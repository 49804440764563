// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AmneziaFilter_amnezia-filter__ASr8c
{
    opacity:1;
    background:white;
    cursor: pointer;
    border:0.5px solid var(--border-clr);
    width:45px;
    height:auto;
    padding:3px;
    transition:all 0.2s;
}

.AmneziaFilter_amnezia-filter-active__A22Yo
{
    border:0.5px solid var(--primary-clr-200);
    box-shadow: var(--primary-clr-100) 0px 0px 6px -1px;
    opacity:1;
    background:white;
    cursor: pointer;
    width:45px;
    height:auto;
    padding:3px;
    transition:all 0.2s;
}

.AmneziaFilter_amnezia-filter-container__s-Uoq
{
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    flex-direction:row;
    height:100%;
}`, "",{"version":3,"sources":["webpack://./src/components/AmneziaFilter.module.css"],"names":[],"mappings":"AAAA;;IAEI,SAAS;IACT,gBAAgB;IAChB,eAAe;IACf,oCAAoC;IACpC,UAAU;IACV,WAAW;IACX,WAAW;IACX,mBAAmB;AACvB;;AAEA;;IAEI,yCAAyC;IACzC,mDAAmD;IACnD,SAAS;IACT,gBAAgB;IAChB,eAAe;IACf,UAAU;IACV,WAAW;IACX,WAAW;IACX,mBAAmB;AACvB;;AAEA;;IAEI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,oBAAoB;IACpB,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".amnezia-filter\r\n{\r\n    opacity:1;\r\n    background:white;\r\n    cursor: pointer;\r\n    border:0.5px solid var(--border-clr);\r\n    width:45px;\r\n    height:auto;\r\n    padding:3px;\r\n    transition:all 0.2s;\r\n}\r\n\r\n.amnezia-filter-active\r\n{\r\n    border:0.5px solid var(--primary-clr-200);\r\n    box-shadow: var(--primary-clr-100) 0px 0px 6px -1px;\r\n    opacity:1;\r\n    background:white;\r\n    cursor: pointer;\r\n    width:45px;\r\n    height:auto;\r\n    padding:3px;\r\n    transition:all 0.2s;\r\n}\r\n\r\n.amnezia-filter-container\r\n{\r\n    display:flex;\r\n    justify-content:center;\r\n    align-items:center;\r\n    align-content:center;\r\n    flex-direction:row;\r\n    height:100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amnezia-filter": `AmneziaFilter_amnezia-filter__ASr8c`,
	"amnezia-filter-active": `AmneziaFilter_amnezia-filter-active__A22Yo`,
	"amnezia-filter-container": `AmneziaFilter_amnezia-filter-container__s-Uoq`
};
export default ___CSS_LOADER_EXPORT___;
